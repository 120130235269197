import {
    ICategoryHierarchyViewProps,
    IRefineMenuViewProps,
    ISearchResultContainerViewProps,
    ISearchResultModalViewProps,
    ISortByViewProps,
    ITitleViewProps,
    IChoiceSummaryClickNotification,
    isMatchingRefinementCriterion,
    ISearchResultContainerData
} from '@msdyn365-commerce-modules/search-result-container';
// import { IMandsSearchResultContainerProps } from '../definition-extensions/mands-search-result-container.ext.props.autogenerated';
import { Module, Node, LabeledDropdown } from '@msdyn365-commerce-modules/utilities';
import * as React from 'react';
import RefineSubmenu from './custom-components/refine-submenu';
import ChoiceSummary from './custom-components/choice-summary';
import { ProductRefinerValue } from '@msdyn365-commerce/retail-proxy';
import { IMandsSearchResultContainerProps } from '../../../modules/mands-search-result-container';
interface IState {
    rating: boolean;
    activeRefiner: number;
}

/**
 *
 * SearchResultContainerView component
 * @extends {React.PureComponent<ISearchResultContainerViewProps>}
 */

export class SearchResultContainerView extends React.PureComponent<
    ISearchResultContainerViewProps & IMandsSearchResultContainerProps<ISearchResultContainerData>,
    IState
> {
    public _sortAndFilterContainerRef: React.RefObject<LabeledDropdown>;
    constructor(props: ISearchResultContainerViewProps & IMandsSearchResultContainerProps<ISearchResultContainerData>) {
        super(props);
        this.state = {
            rating: false,
            activeRefiner: -1
        };
        this._sortAndFilterContainerRef = React.createRef<LabeledDropdown>();
    }
    public render(): JSX.Element {
        const { props } = this;
        const mobileView =
            props.context.request && props.context.request.device && props.context.request.device.Type === 'Mobile' ? true : false;
        const {
            SearchResultContainer,
            products,
            pagination,
            ProductsContainer,
            ProductSectionContainer,
            isMobile,
            modalToggle,
            searchResultModal,
            TitleViewProps,
            refineMenu,
            categoryHierarchy,
            sortByOptions,
            CategoryNavContainer,
            RefineAndProductSectionContainer,
            errorMessage
        } = this.props;
        let { choiceSummary } = this.props;
        choiceSummary = this._getChoiceSummary();
        if (isMobile) {
            return (
                <Module {...SearchResultContainer}>
                    {this.renderCategoryHierarchy(categoryHierarchy)}
                    {props.slots && props.slots.content && (
                        <div>
                            {props.slots.content.map((children: React.ReactNode, index: number) => {
                                return (
                                    <div className='col-12' key={index}>
                                        {children}
                                    </div>
                                );
                            })}
                        </div>
                    )}
                    {this.renderTitle(TitleViewProps)}
                    {modalToggle}
                    {TitleViewProps.title.titleCount}
                    {this.createSearchResultModal(searchResultModal, refineMenu, sortByOptions, mobileView)}

                    <Node {...ProductSectionContainer}>
                        <div className='row search-result-row'>
                            <div className='col-xs-6 col-lg-7 product-quantity'>
                                <div className='filters-refine'>
                                    <i className='filter-icon' />
                                    {pagination}
                                </div>
                                <Node {...CategoryNavContainer}>{TitleViewProps && this.renderTitleCount(TitleViewProps)}</Node>
                            </div>
                            <div className='col-xs-6 col-md-6 col-lg-5'>{sortByOptions && this.renderSort(sortByOptions)}</div>
                        </div>
                        <Node {...ProductsContainer}>
                            {errorMessage}
                            {products}
                        </Node>
                        <div className='bottom-pagination-plp'>{pagination}</div>
                    </Node>
                </Module>
            );
        }
        return (
            <Module {...SearchResultContainer}>
                <Node {...CategoryNavContainer}>{categoryHierarchy && this.renderCategoryHierarchy(categoryHierarchy)}</Node>
                {props.slots && props.slots.content && (
                    <div>
                        {props.slots.content.map((children: React.ReactNode, index: number) => {
                            return (
                                <div className='col-12' key={index}>
                                    {children}
                                </div>
                            );
                        })}
                    </div>
                )}
                <Node {...RefineAndProductSectionContainer}>
                    {refineMenu && this.renderRefiner(refineMenu, mobileView)}
                    {choiceSummary}
                    <Node {...ProductSectionContainer}>
                        <div className='ms-search-result-wrapper-title-choice-summary'>
                            {TitleViewProps && this.renderTitle(TitleViewProps)}
                        </div>
                        {TitleViewProps.title.titleCount}
                        <div className='ms-search-result-wrapper-sort-by-category'>{sortByOptions && this.renderSort(sortByOptions)}</div>
                        <Node {...ProductsContainer}>
                            {errorMessage}
                            {products}
                        </Node>
                        {pagination}
                    </Node>
                </Node>
            </Module>
        );
    }
    public componentDidMount = () => {
        this.hideRatingFromSortDropdown();
    };

    public componentDidUpdate = () => {
        this.hideRatingFromSortDropdown();
    };

    public hideRatingFromSortDropdown = () => {
        const sort = document.getElementById('categorySortByDropdown') as HTMLSelectElement;
        if (sort && sort.options) {
            for (let i = 0; i < sort.options.length; i++) {
                if (sort.options[i].value.includes('Rating')) {
                    sort.options[i].style.display = 'none';
                }
                if (sort.options[i].value.includes('Price: Low to High')) {
                    sort.options[i].style.display = 'none';
                }
                if (sort.options[i].value.includes('Price: High to Low')) {
                    sort.options[i].style.display = 'none';
                }
            }
        }
    };

    public createSearchResultModal = (
        modalProps: ISearchResultModalViewProps,
        refineMenu: IRefineMenuViewProps,
        sortByDropDown: ISortByViewProps,
        mobileView: boolean
    ): JSX.Element => {
        return React.cloneElement(
            modalProps.modal,
            {},
            modalProps.modalHeader,
            this.createModalBody(modalProps, refineMenu, sortByDropDown, mobileView),
            modalProps.modalFooter
        );
    };

    private createModalBody = (
        props: ISearchResultModalViewProps,
        refineMenu: IRefineMenuViewProps,
        sortByDropDown: ISortByViewProps,
        mobileView: boolean
    ): JSX.Element | null => {
        if (sortByDropDown) {
            return React.cloneElement(props.modalBody, {}, this.renderSort(sortByDropDown), this.renderRefiner(refineMenu, mobileView));
        }
        return null;
    };

    private renderTitleCount = (props: ITitleViewProps): JSX.Element | null => {
        const { title, TitleContainer } = props;
        if (title) {
            return (
                <Node {...TitleContainer}>
                    <h5>{title.titleCount}</h5>
                </Node>
            );
        }
        return null;
    };

    private setRating = () => {
        return () => this.setState({ rating: !this.state.rating });
    };

    private renderRefiner = (props: IRefineMenuViewProps, isMobile: boolean): JSX.Element | null => {
        const { refiners, RefineMenuContainer, RefinerSectionContainer } = props;
        const { rating } = this.state;
        isMobile = this.props.isMobile;
        let { choiceSummary } = this.props;
        choiceSummary = this._getChoiceSummary();
        if (refiners) {
            return (
                <Node {...RefinerSectionContainer}>
                    <Node {...RefineMenuContainer}>
                        {!isMobile &&
                            refiners.map((submenu, index) => submenu !== null && index < 6 && this.renderSubmenu(submenu, index, rating))}
                        {!isMobile && refiners.length > 6 && !rating && (
                            <div role='button' className='show-more' onClick={this.setRating}>
                                {'Show all filters'}
                            </div>
                        )}
                    </Node>
                    <Node {...RefineMenuContainer}>
                        {isMobile ? (
                            <div className='ms-search-result__toggle'>
                                <div className='ms-search-result__collection-count'>{this.props.TitleViewProps.title.titleCount}</div>
                                <div className='ms-search-result__choice-summary'>{choiceSummary}</div>
                            </div>
                        ) : null}
                        {!isMobile &&
                            refiners.map(
                                (submenu, index) => submenu !== null && index > 5 && rating && this.renderSubmenu(submenu, index, rating)
                            )}
                        {!isMobile && refiners.length > 6 && rating && (
                            <div role='button' className='show-more' onClick={this.setRating}>
                                {'Show less filters'}
                            </div>
                        )}
                        {isMobile && refiners.map((submenu, index) => submenu !== null && this.renderSubmenu(submenu, index, rating))}
                    </Node>
                </Node>
            );
        }
        return null;
    };

    private renderSort = (props: ISortByViewProps): JSX.Element | null => {
        const { SortingContainer, sortByDropDown } = props;
        if (sortByDropDown) {
            return <Node {...SortingContainer}>{sortByDropDown}</Node>;
        }
        return null;
    };

    // @ts-ignore
    private renderSubmenu = (submenu, index: number, rating: boolean) => {
        const subMenu = submenu && submenu.props;
        if (subMenu.productRefinerHierarchy.KeyName.toLowerCase() === 'color') {
            subMenu.productRefinerHierarchy.KeyName = 'Colour';
        }
        return subMenu.productRefinerHierarchy.KeyName.toLowerCase() !== 'rating' &&
            subMenu.productRefinerHierarchy.KeyName.toLowerCase() !== 'category' ? (
            <RefineSubmenu
                productRefinerHierarchy={subMenu.productRefinerHierarchy}
                selectedRefinerValues={subMenu.selectedRefinerValues}
                refineItemCommonProps={subMenu.refineItemCommonProps}
                minValueSliderThumbAriaLabel={subMenu.minValueSliderThumbAriaLabel}
                maxValueSliderThumbAriaLabel={subMenu.maxValueSliderThumbAriaLabel}
                key={index}
                onUpdateRefiners={subMenu.onUpdateRefiners}
                urlBuilder={subMenu.urlBuilder}
                isExpandedOnInitialLoad={false}
                tempRangeTypeTODO={subMenu.tempRangeTypeTODO}
                context={subMenu.context}
                moduleId={subMenu.moduleId}
                moduleTypeName={subMenu.moduleTypeName}
                className={index === 4 && !rating ? 'hidden' : ''}
                isDisabled={false}
                refinerId={index}
                toggleAllRefiners={this.toggleAllRefiners}
                isOpen={this.state.activeRefiner === index && this.state.activeRefiner !== -1 ? true : false}
            />
        ) : null;
    };

    private toggleAllRefiners = (activeRefiner: number) => {
        const currentRefinerState = this.state.activeRefiner;
        if (activeRefiner === currentRefinerState) {
            this.setState({ activeRefiner: -1 });
        } else {
            this.setState({ activeRefiner });
        }
    };
    private renderCategoryHierarchy = (props: ICategoryHierarchyViewProps): JSX.Element | null => {
        const { CategoryHierarchyContainer, categoryHierarchyList, categoryHierarchySeparator } = props;
        if (categoryHierarchyList) {
            return (
                <Node {...CategoryHierarchyContainer}>
                    {categoryHierarchyList.map((category, index) => (
                        <React.Fragment key={index}>
                            {category}
                            {categoryHierarchyList && categoryHierarchyList[index + 1] && categoryHierarchySeparator}
                        </React.Fragment>
                    ))}
                </Node>
            );
        }

        return null;
    };

    private renderTitle = (props: ITitleViewProps): JSX.Element | null => {
        const { title, TitleContainer } = props;
        if (title) {
            return (
                <Node {...TitleContainer}>
                    <h1>{title.titleText}</h1>
                </Node>
            );
        }
        return null;
    };
    private _getChoiceSummary = (): React.ReactNode | null => {
        const { resources, data, telemetry } = this.props;
        const selectedRefiners = (data.listPageState.result && data.listPageState.result.activeFilters) || [];
        const refinersData = data.refiners.result;
        if (!refinersData) {
            return null;
        }

        return (
            <ChoiceSummary
                classNames='ms-choice-summary-by-category'
                clearAllText={resources.clearAllText}
                label={resources.choiceSummaryLabel}
                selectedChoices={selectedRefiners}
                refinerHierarchy={refinersData}
                choiceFormat={resources.choiceFormat}
                choiceRangeValueFormat={resources.choiceRangeValueFormat}
                telemetry={telemetry}
                onChoiceClicked={this._onChoiceClicked}
                urlBuilder={this._buildRefinerUrlForChoiceSummary}
                choiceAriaLabel={resources.choiceAriaLabel}
            />
        );
    };
    private _onChoiceClicked = (notification: IChoiceSummaryClickNotification): void => {
        const { data, telemetry } = this.props;
        if (!data.listPageState.result) {
            telemetry.warning('[choice-summary-by-category._onChoiceClicked]ListPageState unavailable, unable to update refiners.');
            return;
        }

        let currentRefinementCriteria = (data.listPageState.result && data.listPageState.result.activeFilters) || [];

        if (!notification.clearAll) {
            const selectedChoice = notification.choiceClicked;
            if (!selectedChoice) {
                telemetry.warning('[choice-summary-by-category._onChoiceClicked] Choice could not be determined');
                return;
            }
            currentRefinementCriteria = currentRefinementCriteria.filter(
                (selectedCriterion: ProductRefinerValue) => !isMatchingRefinementCriterion(selectedChoice, selectedCriterion)
            );
        } else {
            currentRefinementCriteria = [];

            // set focus to sort and filter
            const dropdownElementId = this._sortAndFilterContainerRef.current && this._sortAndFilterContainerRef.current.props.dropdownId;
            const dropdownElement = dropdownElementId && document.getElementById(dropdownElementId);
            setTimeout(() => {
                dropdownElement && dropdownElement.focus();
            }, 50);
        }

        data.listPageState.result.currentPageNumber = 0;
        data.listPageState.result.activeFilters = currentRefinementCriteria;
    };
    private _buildRefinerUrlForChoiceSummary = (selectedRefiner: ProductRefinerValue, isClearAll: boolean): string => {
        const { data, telemetry } = this.props;
        const fullUrl = this.props.context.request.url.requestUrl;

        if (!data.listPageState.result) {
            telemetry.warning('[choice-summary-by-category._buildRefinerUrl]ListPageState unavailable, unable to create refiner URL.');
            return fullUrl.href;
        }

        let currentRefinementCriteria = (data.listPageState.result && data.listPageState.result.activeFilters) || [];

        if (!isClearAll) {
            if (!selectedRefiner) {
                telemetry.warning('[choice-summary-by-category._buildRefinerUrl] URL for Choice could not be determined');
                return fullUrl.href;
            }
            currentRefinementCriteria = currentRefinementCriteria.filter(
                (selectedCriterion: ProductRefinerValue) => !isMatchingRefinementCriterion(selectedRefiner, selectedCriterion)
            );
        } else {
            currentRefinementCriteria = [];
        }

        return this.props.context.request.url.requestUrl.href;
    };
}

export default SearchResultContainerView;
